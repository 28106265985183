<template>
    <v-container fluid class="px-6" >

      <v-card elevation="2">

        <v-card-title class="px-6 card-header white--text">
            <v-icon class="mr-3 white--text">mdi-database-import-outline</v-icon> <h3>Maintenance Data Entry</h3>
        </v-card-title>

        <v-form class="main-form-card" style="height: calc(100vh - 190px);overflow-y:auto;overflow-x:hidden;">

          <v-card class="mt-8 pa-5 mx-4" outlined>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                  v-model="modelStationSelected"
                  v-on:change="selectValueStation"
                  :items="itemStation"
                  label="Station"
                  prepend-icon="mdi-store-24-hour"
                  required
                  outlined 
                  dense
                  :loading="loadingInputStation"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete
                  v-model="parameterSelected"
                  v-on:change="selectValueParams"
                  :items="itemParams"
                  label="Parameter"
                  prepend-icon="mdi-water"
                  multiple
                  chips
                  required
                  outlined 
                  dense
                  :loading="loadingInputParams"
                  >
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggle"
                        >
                        <v-list-item-action>
                            <v-icon :color="parameterSelected.length > 0 ? 'indigo darken-4' : ''">
                            {{ icon }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                            Select All
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row>

                <v-col cols="12" sm="6">
                    <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="modalStartCalendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined 
                        dense
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                      v-on:change="selectStartDate"
                      v-model="startDate"
                      @input="modalStartCalendar = false"
                      ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="startTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          label="Start Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          hint="Please select AM/PM first, then time hours and minutes for rendering time"
                          outlined 
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="startTime"
                        full-width
                        ampm-in-title
                        @click:minute="$refs.menu.save(startTime)"
                        v-on:change="selectStartTime(startTime)"
                      ></v-time-picker>
                    </v-menu>
                </v-col>

              </v-row>

              <v-row justify="center">
                  <v-btn class="button white--text mr-5" @click="resetForm">
                    RESET
                  </v-btn>

                  <v-btn :disabled="modelStationSelected == '' || parameterSelected.length == 0" class="button white--text" @click="checkSummary">
                    CHECK
                  </v-btn>
              </v-row>
            </v-container>
          </v-card>

          <!-- <v-card class="mt-8 pa-5"> -->

            <!-- <v-card-subtitle class="primary--text pa-0 text-h5">Summary</v-card-subtitle> -->

            <v-card-title class="px-6 mt-10 card-header white--text">
                <v-icon class="mr-3 white--text">mdi-google-analytics</v-icon> <h3>Summary</h3>
            </v-card-title>

            <v-data-table
            :headers="theadSummaryMaintenance"
            :items="tbodySummaryMaintenance"
            :header-props="{ sortIcon: null }"
            class="elevation-1 my-6 headerDtSarawak mx-4"
            :items-per-page="10"
            no-select-on-click
            fixed-header
            >
            </v-data-table>

            <v-row justify="center">
              <v-btn
              class="button white--text"
              :disabled="disableBtnSubmit"
              @click="submitSummaryMaintenance">
                SUBMIT
              </v-btn>
            </v-row>

            
            
          <!-- </v-card> -->

          <br><br>
          
        </v-form>

      </v-card>

      <!-- Popups/Dialogs Success Add Maintenance Record-->
      <div class="text-center">
          <v-dialog
          v-model="dialogSuccessAdd"
          width="500"
          >
          <v-card>
              <v-card-title class="text-h5 primary white--text">
              Success! <v-icon style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
              </v-card-title>

              <v-card-text style="padding:20px 24px;font-weight:bold;">
              Maintenance record successfully added.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  style="color:#106f79 !important;"
                  color="success"
                  text
                  @click="dialogSuccessAdd = false;resetForm();"
              >
                  OK
              </v-btn>
              </v-card-actions>
          </v-card>
          </v-dialog>
      </div>

      <!-- Popups/Dialogs Error Add Maintenance Record-->
      <div class="text-center">
          <v-dialog
          v-model="dialogErrorAdd"
          width="500"
          >
          <v-card>
              <v-card-title class="text-h5 error white--text">
              Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
              </v-card-title>

              <v-card-text style="padding:20px 24px;font-weight:bold;">
              Something when wrong. Please try again.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  style="color:red !important;"
                  color="error"
                  text
                  @click="dialogErrorAdd = false"
              >
                  OK
              </v-btn>
              </v-card-actions>
          </v-card>
          </v-dialog>
      </div>
        
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({

    modelStationSelected: null,
    itemStation: [],
    dataOneStation: [],
    globalAllParameter: [],
    itemParams: [],
    itemKeysParamsSelected: [],
    parameterSelected: [],
    startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    startTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),

    // For Sending
    sendStation: null,
    sendParameter: [],
    sendDateTime: null,

    // Engine Vuetify
    valueStation: null,
    modalStartCalendar: false,
    menu2: false,
    todayDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    todayTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5),

    // Table Summary
    theadSummaryMaintenance: [
      {text: "Station ID", value: "stationId", sortable: true},
      {text: "Parameter", value: "params", sortable: true},
      {text: "Start Date & Time", value: "stdate", sortable: true},
    ],
    tbodySummaryMaintenance: [],

    loadingInputStation: false,
    loadingInputParams: false,
    disableBtnSubmit: true,

    dialogSuccessAdd: false,
    dialogErrorAdd: false,

  }),
  methods:{

    load(){

      // console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5));

      this.loadStationList();
      this.loadParams();
    },

    loadStationList(){

      this.loadingInputStation = true;
      
      this.itemStation = [];

      axios.get(this.globalUrl+'mqm2/miqims/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // console.log(response.data);

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(response.data[i].STATION_ID + " - " + response.data[i].LOCATION);
          }
          this.loadingInputStation = false;
          
      })
      .catch(error => {
          console.log(error);
          this.loadingInputStation = false;
      })
    },

    loadParams(){
      axios.get(this.globalUrl+'mqm2/miqims/params', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {
          // console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
              this.globalAllParameter.push(response.data[i]);
          }
          
      })
      .catch(error => {
          console.log(error);
      })
    },

    selectValueStation: function(e) {

      this.loadingInputParams = true;

      console.log(e);
      this.sendStation = e.split(" - ")[0];
      let station = e.split(" - ")[0];

      axios.get(this.globalUrl+'mqm2/miqims/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {

          this.dataOneStation = [];
          this.itemKeysParamsSelected = [];
          this.itemParams = [];
        
          let data = response.data

          // console.log(data); 

          for(let i in data){
            if (data[i].STATION_ID == station) {
              this.dataOneStation.push(data[i]);
            }
          }

          // console.log(this.dataOneStation);

          let keyData = Object.keys(this.dataOneStation[0])
          for(let i in keyData){
            for(let x in this.globalAllParameter){
              if (this.dataOneStation[0][keyData[i]] == this.globalAllParameter[x].reading
              || "_"+this.dataOneStation[0][keyData[i]] == this.globalAllParameter[x].reading) {
                this.itemKeysParamsSelected.push(this.globalAllParameter[x]);
              }
            }
          }

          for(let i in this.itemKeysParamsSelected){
            this.itemParams.push(this.itemKeysParamsSelected[i].text + ' || ' + this.itemKeysParamsSelected[i].value);
          }   
          this.loadingInputParams = false;       
          
      })
      .catch(error => {
          console.log(error);
          this.loadingInputParams = false;
      })
    },

    selectValueParams: function(e) {
      // console.log(e);
    },

    toggle () {
        this.$nextTick(() => {
            if (this.checkedAllParams) {
                // console.log(this.parameterSelected);
                this.parameterSelected = []
            } else {
                this.parameterSelected = this.itemParams.slice()
            }
        })
    },

    selectStartDate: function(e) {
      // console.log(e);
      // this.sendDateTime = e+"T"+this.startTime;
    },

    selectStartTime: function(e) {
      // console.log(e);
      // this.sendDateTime = this.startDate+"T"+e;
    },

    resetForm(){
      this.modelStationSelected = null;
      this.itemStation = [];
      this.itemParams = [];
      this.parameterSelected = [];
      this.startDate = this.todayDate;
      this.startTime = this.todayTime;
      this.sendStation = null;
      this.sendParameter = [];
      this.sendDateTime = null;
      this.tbodySummaryMaintenance = [];
      this.disableBtnSubmit = true;

      let myInterval = setInterval(() => {
          this.loadStationList();
          clearInterval(myInterval);
      }, 1000);
      
    },

    checkSummary(){

      this.tbodySummaryMaintenance = [];

      if (this.itemStation.length > 0 && this.parameterSelected.length > 0) {

        this.sendParameter = [];
        for(let i in this.parameterSelected){
          this.sendParameter.push(this.parameterSelected[i].split(" || ")[1]);
        }
        // console.log(this.sendParameter);
        this.sendDateTime = this.startDate + "T" + this.startTime + ":00";
        // console.log(this.sendDateTime);

        for(let i in this.parameterSelected){
          this.tbodySummaryMaintenance.push(
            {
              stationId: this.sendStation,
              params: this.parameterSelected[i],
              stdate: this.useConvertDisplayDatetime(this.sendDateTime),
            }
          )
        }

        this.disableBtnSubmit = false;

      }
      else{
        console.log("selection null");
        this.disableBtnSubmit = true;
      }

      
    },

    submitSummaryMaintenance(){

      if (this.sendStation != null && this.sendParameter.length > 0) {

        axios.get(this.globalUrl+'mqm2/maint/addmaintcal?stationid='+this.sendStation+'&flag=M&param='+this.sendParameter+'&startdt='+this.sendDateTime, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            let data = response.data;
            console.log(data);
            this.dialogSuccessAdd = true;
        })
        .catch(error => {
            console.log(error);
            this.dialogSuccessAdd = false;
            this.dialogErrorAdd = true;
        })

      }
      else{
        console.log("null");
      }

    }
      
  },
  mounted(){
    this.load();
  },
  computed: {
      checkedAllParams () {
          // console.log(this.parameterSelected);
          return this.parameterSelected.length === this.itemParams.length
          // return this.parameterSelected.length === this.itemParams.length
      },
      checkedSomeParams () {
          return this.parameterSelected.length > 0 && !this.checkedAllParams
      },
      icon () {
          if (this.checkedAllParams) return 'mdi-close-box'
          if (this.checkedSomeParams) return 'mdi-minus-box'
          return 'mdi-checkbox-blank-outline'
      },
  }
}
</script>

<style lang="scss">

@import '~scss/main';

.v-subheader {
  height: 30px;
}
</style>